import React, { FC } from 'react';

import { ILandingVideoBannerComponentProps } from 'components/LandingVideoBanner/models';
import 'components/LandingVideoBanner/LandingVideoBanner.scss';
import useScreenRecognition from 'hooks/useScreenRecognition';
import {
  ComeTogetherWithDurexLogo,
  DefaultDesktopLogo,
  DefaultMobileLogo,
  DefaultTabletLogo,
} from './icons';

const LandingVideoBanner: FC<ILandingVideoBannerComponentProps> = ({
  properties,
  defaultLogo = true,
}) => {
  const { isMobile, isTablet, isDesktop } = useScreenRecognition();
  let videoUrl;
  if (isMobile) videoUrl = properties.videoNarrow.url;
  if (isTablet) videoUrl = properties.videoSquare.url;
  if (isDesktop) videoUrl = properties.videoWide.url;

  const mobileLogo = defaultLogo ? <DefaultMobileLogo /> : <ComeTogetherWithDurexLogo />;
  const tabletLogo = defaultLogo ? <DefaultTabletLogo /> : <ComeTogetherWithDurexLogo />;
  const desktopLogo = defaultLogo ? <DefaultDesktopLogo /> : <ComeTogetherWithDurexLogo />;

  return (
    <div className="landing-page-block landing-page-block--video-banner">
      <video autoPlay muted loop src={videoUrl} />
      <a href="/" className="landing-page-block__logo">
        {isMobile ? mobileLogo : isDesktop ? desktopLogo : tabletLogo}
      </a>
      <a className="landing-page-block__down-arrow" href="#next-section">
        <svg
          width="20"
          height="12"
          viewBox="0 0 20 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M1 1L10.1023 10L19 1" stroke="#255AA4" strokeWidth="2.5" strokeMiterlimit="10" />
        </svg>
      </a>
      <div id="next-section" />
    </div>
  );
};

export default LandingVideoBanner;
