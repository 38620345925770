import React, { FC } from 'react';
import Button from 'common/Button';
import { DangerouslySetInnerHtml } from 'layout';
import GatsbyImage from 'common/GatsbyImage';
import { ISimpleCardCarouselItem } from './models';

const SimpleCardCarouselItem: FC<ISimpleCardCarouselItem> = ({
  image,
  content,
  link,
  buttonImage,
}) => {
  return (
    <div className="simple-card-carousel-item">
      <GatsbyImage
        className="simple-card-hero-image"
        fluid={image?.gatsbyImage?.childImageSharp?.fluid}
        alt={image?.imageAltLabel}
      />
      <DangerouslySetInnerHtml className="simple-card-content" html={content} />
      <Button link={link}>
        <GatsbyImage
          fluid={buttonImage?.gatsbyImage?.childImageSharp?.fluid}
          alt={buttonImage?.imageAltLabel}
        />
        Instagram
      </Button>
    </div>
  );
};

export default SimpleCardCarouselItem;
