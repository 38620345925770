import React, { FC } from 'react';
import { DangerouslySetInnerHtml } from 'layout';
import GatsbyImage from 'common/GatsbyImage';
import { IFavoritesBlock } from './models';
import './FavoritesBlock.scss';

const FavoritesBlock: FC<IFavoritesBlock> = ({ properties }) => {
  const { favoritesBlockItems, favoritesBlockTitle } = properties;

  return (
    <div className="favorites-block">
      <DangerouslySetInnerHtml className="favorites-block__title" html={favoritesBlockTitle} />
      <div className="favorites-block__images">
        {favoritesBlockItems.map((item) => (
          <a href={item.properties.favoritesBlockItemLink[0].url} target="_blank" rel="noreferrer">
            <GatsbyImage
              fluid={item.properties.favoritesBlockItemBackground.gatsbyImage.childImageSharp.fluid}
              alt={item.properties.favoritesBlockItemBackground.imageAltLabel}
            />
          </a>
        ))}
      </div>
    </div>
  );
};

export default FavoritesBlock;
