import React, { FC } from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Thumbs, Pagination } from 'swiper';

import { DangerouslySetInnerHtml } from 'layout';
import useScreenRecognition from '../../hooks/useScreenRecognition';
import { ISimpleCardCarousel } from './models';

import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import './SimpleCardCarousel.scss';
import SimpleCardCarouselItem from './SimpleCardCarouselItem';

SwiperCore.use([Navigation, Pagination, Thumbs]);

const SimpleCardCarousel: FC<ISimpleCardCarousel> = ({ properties }) => {
  const { isMobile, isTablet, isDesktop } = useScreenRecognition();

  const { description_, title, simpleCardList } = properties;

  let backgroundImage;
  if (properties.backgroundImage?.variants) {
    if (isMobile)
      backgroundImage = properties.backgroundImage?.variants?.find(
        (variant) => variant.alias === 'Mobile'
      )?.url;
    if (isTablet)
      backgroundImage = properties.backgroundImage?.variants?.find(
        (variant) => variant.alias === 'Tablet'
      )?.url;
    if (isDesktop)
      backgroundImage = properties.backgroundImage?.variants?.find(
        (variant) => variant.alias === 'Desktop'
      )?.url;
  }

  return (
    <div
      className="simple-card-carousel"
      style={{
        backgroundImage: backgroundImage ? `url(${backgroundImage})` : undefined,
      }}
    >
      {title ? <h2 className="simple-card-carousel__title">{title}</h2> : null}
      {description_ ? (
        <DangerouslySetInnerHtml className="simple-card-carousel__subtitle" html={description_} />
      ) : null}

      {isMobile || isTablet ? (
        <div className="slider">
          <Swiper slidesPerView={1} spaceBetween={0} pagination={{ clickable: true }}>
            {simpleCardList?.map((card, index) => (
              <SwiperSlide key={index}>
                <SimpleCardCarouselItem
                  content={card.properties.description}
                  image={card.properties.heroImage}
                  buttonImage={card.properties.buttonIcon}
                  link={card.properties.linkList[0].url}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      ) : (
        <div className="simple-card-carousel__wrapper">
          {simpleCardList?.map((card) => (
            <SimpleCardCarouselItem
              content={card.properties.description}
              image={card.properties.heroImage}
              buttonImage={card.properties.buttonIcon}
              link={card.properties.linkList[0].url}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default SimpleCardCarousel;
