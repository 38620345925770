import React, { FC } from 'react';
import classNames from 'classnames';

import './LandingPage.scss';
import { Layout } from 'gatsby-theme-husky/src/layout';
import { graphql } from 'gatsby';
import RelatedProducts from 'gatsby-theme-husky/src/components/RelatedProducts';
import LandingVideoBanner from 'gatsby-theme-husky/src/components/LandingVideoBanner';
import LandingTextBlock from 'gatsby-theme-husky/src/components/LandingTextBlock';
import LandingTiktokFeed from 'gatsby-theme-husky/src/components/LandingTiktokFeed';
import LandingVideo from 'gatsby-theme-husky/src/components/LandingVideo';
import SimpleCardCarousel from 'gatsby-theme-husky/src/components/SimpleCardCarousel';
import FavoritesBlock from 'gatsby-theme-husky/src/components/FavoritesBlock';
import useScreenRecognition from 'gatsby-theme-husky/src/hooks/useScreenRecognition';
import { ILandingPageComponentProps } from './models';

const LandingPage: FC<ILandingPageComponentProps> = ({
  data: {
    umbracoLanding: {
      url,
      defaultCompositions,
      seoMetaTitle,
      seoMetaDescription,
      seoMetaKeywords,
      seoExternalHreflangs,
      blocks,
      relatedProductsCardCtaButton,
      relatedProductsCtaButtonShow,
      relatedProductsTitle,
      relatedProductsCardCTAButtonAriaLabel,
      relatedProductsCtaButton,
      generalBackground,
    },
    relatedProducts,
  },
}) => {
  const { isMobile, isTablet, isDesktop } = useScreenRecognition();
  const isComeWithDurexPage = url.includes('come-together-with-durex');

  let backgroundImage;
  if (generalBackground?.variants) {
    if (isMobile)
      backgroundImage = generalBackground?.variants?.find((variant) => variant.alias === 'Mobile')
        ?.url;
    if (isTablet)
      backgroundImage = generalBackground?.variants?.find((variant) => variant.alias === 'Tablet')
        ?.url;
    if (isDesktop)
      backgroundImage = generalBackground?.variants?.find((variant) => variant.alias === 'Desktop')
        ?.url;
  }

  return (
    <Layout
      defaultCompositions={defaultCompositions}
      headerTransparent
      hideHeader
      seo={{
        seoMetaDescription,
        seoMetaKeywords,
        seoMetaTitle,
        seoExternalHreflangs,
      }}
    >
      <div
        className={classNames('landing-page', {
          'come-with-durex-landing': isComeWithDurexPage,
        })}
        style={{
          backgroundImage: backgroundImage ? `url(${backgroundImage})` : undefined,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      >
        {blocks.map((block, index) => {
          switch (block.structure) {
            case 'Video Banner':
              return (
                <LandingVideoBanner
                  defaultLogo={!isComeWithDurexPage}
                  properties={block.properties}
                  key={index}
                />
              );
            case 'Landing Text Block':
              return <LandingTextBlock properties={block.properties} key={index} />;
            case 'Landing TikTok Feed':
              return <LandingTiktokFeed properties={block.properties} key={index} />;
            case 'Landing Video':
              return <LandingVideo properties={block.properties} key={index} />;
            case 'Simple Card Carousel':
              return <SimpleCardCarousel properties={block.properties} key={index} />;
            case 'Favorites Block':
              return <FavoritesBlock properties={block.properties} key={index} />;
            default:
              return null;
          }
        })}

        {!isComeWithDurexPage && (
          <RelatedProducts
            mainProduct={{
              nodes: [],
            }}
            {...{
              relatedProducts,
              relatedProductsCardCtaButton,
              relatedProductsCtaButton,
              relatedProductsTitle,
              relatedProductsCardCTAButtonAriaLabel,
              relatedProductsCtaButtonShow,
            }}
          />
        )}
      </div>
    </Layout>
  );
};

export const query = graphql`
  query($url: String, $lang: String, $relatedProductsLinks: [String]) {
    umbracoLanding(url: { eq: $url }, lang: { eq: $lang }) {
      url
      seoMetaDescription
      seoMetaKeywords
      seoMetaTitle
      seoExternalHreflangs {
        hreflang: key
        href: value
      }
      defaultCompositions {
        ...DefaultCompositionsFragment
      }
      blocks {
        structure
        properties {
          videoSquare {
            url
          }
          videoNarrow {
            url
          }
          videoWide {
            url
          }
          backgroundImage {
            variants {
              url
              alias
            }
          }
          heroImage {
            altText
            variants {
              url
              alias
            }
          }
          content
          backgroundStyle
          youtubeVideoId
          reverseHero
          noPadding
          title
          description_
          simpleCardList {
            properties {
              description
              buttonText
              buttonIcon {
                ...FragmentGatsbyProps
                gatsbyImage {
                  childImageSharp {
                    fluid(maxWidth: 700, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              linkList {
                url
              }
              heroImage {
                ...FragmentGatsbyProps
                gatsbyImage {
                  childImageSharp {
                    fluid(maxWidth: 700, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
          favoritesBlockTitle
          favoritesBlockItems {
            properties {
              favoritesBlockItemLink {
                url
              }
              favoritesBlockItemBackground {
                ...FragmentGatsbyProps
                gatsbyImage {
                  childImageSharp {
                    fluid(maxWidth: 700, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
      relatedProductsCardCtaButton
      relatedProductsCtaButtonShow
      relatedProductsTitle
      relatedProductsCardCTAButtonAriaLabel
      relatedProductsCtaButton {
        url
      }
      relatedProductsLinks
      generalBackground {
        variants {
          url
          alias
        }
      }
    }
    relatedProducts: allUmbracoProducts(filter: { url: { in: $relatedProductsLinks } }) {
      nodes {
        url
        properties {
          image {
            properties {
              imageAltLabel
            }
            localImage {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          title
        }
      }
    }
  }
`;

export default LandingPage;
